import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import {Container,Row,Col,Toast,Button,Form,Label,Control} from 'react-bootstrap';
import Axios from 'axios';
import Qs from 'querystring';
import { FacebookProvider, LoginButton } from 'react-facebook';
import { GoogleLogin } from 'react-google-login';
import Cookies from 'universal-cookie';

import './earthly.css';
import earthly_logo from "../images/earthly_logo.png";
import google_logo from '../images/google_img.png';
import facebook_login from '../images/facebook_login.png';
import message_icon from '../images/email.svg'
import earthly_sign_up from '../images/signup_img.png'
import earthly_bottom_signup from '../images/bottom_signup.png'
import hero_img from '../images/earthly_banner.png';
import {API_URL,CONFIG_WITHOUT_TOKEN,FACEBOOK_ID,GOOGLE_LOGIN_ID} from '../appconstant/index';
const cookies = new Cookies();
const Login = () => {
  const [showlogin,setShowlogin] = useState("mainpage");
  const [show, setShow] = useState(false);
  const [emessage, setEmessage] = useState(null);

  const login_initial_state = {
    email:"",
    password:"",
  }
  const register_initial_state = {
    registeremail:"",
    registerpassword:"",
    first_name:"",
    last_name:"",
  }
const [state , setState] = useState(login_initial_state);
const [rstate, setRstate] = useState(register_initial_state)
  const loginscreen = () =>{
      setShowlogin("loginpage");
  }
  const register = () =>{
    setShowlogin("mainpage");
  }

  const inputChange = (e) => {
    const {id , value} = e.target
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
  }
  const inputRegisterChange = (e) => {
    const {id , value} = e.target
        setRstate(prevState => ({
            ...prevState,
            [id] : value
        }))
  }
  const handleSubmit = (e) => {
      e.preventDefault();
      let invite_code = localStorage.getItem("invite_code");
      let login_data = {
        email:state.email,
        password:state.password,
        invite_by:invite_code,
      }
      Axios.post(API_URL + '/apis/login', Qs.stringify(login_data),CONFIG_WITHOUT_TOKEN)
                .then((response) => {
                      if(response.data.error == 0){
                        cookies.set('earthly_token',response.data.token, { path: '/',expires: new Date(new Date().getTime()+1000*60*60*24*365) });
                        setState(login_initial_state);
                        localStorage.setItem("earthly_web_token",response.data.token);
                        navigate('/profile');
                      }else if(response.data.error == 1){
                          setEmessage(response.data.message)
                          setShow(true);
                      }
                })
                .catch((error) => {
                    setEmessage(error.message)
                    setShow(true);

                });

    }
  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    let invite_code = localStorage.getItem("invite_code");
    let reister_data = {
      email:rstate.registeremail,
      password:rstate.registerpassword,
      full_name:rstate.full_name,
      invite_by:invite_code,

    }
    Axios.post(API_URL + '/apis/register', Qs.stringify(reister_data),CONFIG_WITHOUT_TOKEN)
              .then((response) => {
                    if(response.data.error == 0){
                      cookies.set('earthly_token',response.data.token, { path: '/',expires: new Date(new Date().getTime() +1000*60*60*24*365) });
                      setRstate(register_initial_state);
                      localStorage.setItem("earthly_web_token",response.data.token);
                      navigate('/tour/');
                    }else if(response.data.error == 1){
                        setEmessage(response.data.message)
                        setShow(true);
                    }
              })
              .catch((error) => {
                setEmessage(error.message)
                setShow(true);
              });

  }


  const handleResponse = (data) => {

    let fb_token = data.tokenDetail.accessToken;
    let invite_code = localStorage.getItem("invite_code");
    let send_token_data = {
      access_token:fb_token,
      invite_by:invite_code,
    }
    Axios.post(API_URL + '/apis/facebook', Qs.stringify(send_token_data),CONFIG_WITHOUT_TOKEN)
              .then((response) => {
                    if(response.data.error == 0){
                      let action = response.data.action;
                      cookies.set('earthly_token',response.data.token, { path: '/',expires: new Date(new Date().getTime() +1000*60*60*24*365)});
                      localStorage.setItem("earthly_web_token",response.data.token);
                      console.log("response",response.data);
                      localStorage.getItem("earthly_user",JSON.stringify(response.data));
                      if(action == "signup"){
                          navigate('/tour/');
                      }else{
                          navigate('/profile/');
                      }
                    }else if(response.data.error == 1){
                        setEmessage(response.data.message)
                        setShow(true);
                    }
              })
              .catch((error) => {
                  setEmessage(error.message)
                  setShow(true);
              });
  }
  const handleError = (error) => {
      console.log(error.message);
  }
  const responseGoogle = (response) => {
    let response_token_data = response.accessToken;
    let invite_code = localStorage.getItem("invite_code");
    let response_token = {
      access_token:response_token_data,
      invite_by:invite_code,
    }
    Axios.post(API_URL + '/apis/google', Qs.stringify(response_token),CONFIG_WITHOUT_TOKEN)
              .then((response) => {
                    if(response.data.error == 0){
                      let action = response.data.action;
                      cookies.set('earthly_token',response.data.token, { path: '/',expires: new Date(new Date().getTime() +1000*60*60*24*365) });
                      localStorage.setItem("earthly_web_token",response.data.token);
                      localStorage.getItem("earthly_user",JSON.stringify(response.data));
                      if(action == "signup"){
                          navigate('/tour/');
                      }else{
                          navigate('/profile');
                      }
                    }else if(response.data.error == 1){
                        setEmessage(response.data.message)
                        setShow(true);
                    }
              })
              .catch((error) => {
                setEmessage(error.message)
                setShow(true);
              });
  }
  const responseGoogleerror = (response) =>{
    console.log(response);
  }
  return (
    <div>
      <SEO title="Login" image={hero_img} />
      <Container fluid className="p-0" style={{overflow:"hidden"}}>
      <Row>
        <Col>
          <div className="col-12 p-0">
             <div className="pt-4 min-vh-100 m-0 signup_image row">
                <div className="col-lg-6 d-flex align-items-center login_img_section" style={{paddingLeft:"0px"}}>
                  <img src={earthly_sign_up} alt="signup image" />
                </div>
                <div className="col-lg-6 col-sm-12 login_card d-flex align-items-center justify-content-center">
                 <div className="card card_width">
                  <div className="card-body mt-1">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <img src={earthly_logo} alt="Earthly Login" style={{height:"50px",marginBottom:"15px"}}/>
                        <h1 className="m-0 login_title">Join Earthly</h1>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 m-0">
                        <p className="login_sub_title">Buy better, live healthier and support causes you care about directly through your everyday purchases</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center m-0">
                        <GoogleLogin
                          clientId={GOOGLE_LOGIN_ID}
                          buttonText="Login"
                          onSuccess={responseGoogle}
                          onFailure={responseGoogleerror}
                          icon = {false}
                          cookiePolicy={'single_host_origin'}
                          className="facebook_btn_custom"
                        >
                            <img src={google_logo} alt="google Login" style={{marginBottom:"15px"}} />
                          </GoogleLogin>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center m-0">

                        <FacebookProvider appId={FACEBOOK_ID}>
                         <LoginButton
                           scope="email"
                           onCompleted={handleResponse}
                           onError={handleError}
                           className="facebook_btn_custom"
                         >
                          <img src={facebook_login} alt="facebook Login" style={{marginBottom:"1px"}} />
                         </LoginButton>
                       </FacebookProvider>
                      </div>
                    </div>
                    <div className="row mt-2 mb-2">
                      <div className="row col-12 text-center m-0">
                        <div className="col-5" style={{borderTop:"1px solid #bdbdbd",marginTop:"14px"}}>
                          <span></span>
                        </div>
                        <div className="col-2 text-center">
                          or
                        </div>
                        <div className="col-5" style={{borderTop:"1px solid #bdbdbd",marginTop:"14px"}}>
                          <span></span>
                        </div>
                      </div>
                    </div>
                    {showlogin == "mainpage" &&
                    <div>
                        <div className="row">
                          <div className="col-12">
                            <Form onSubmit={handleRegisterSubmit} style={{marginBottom:"0px"}}>
                            <Form.Group style={{marginBottom:"0.5rem"}}>
                              <Form.Label className="from_label">Name</Form.Label>
                              <Form.Control
                                type="text"
                                id="full_name"
                                placeholder=""
                                name="full_name"
                                value={rstate.full_name}
                                onChange={inputRegisterChange}
                                required
                               />
                            </Form.Group>
                              <Form.Group style={{marginBottom:"0.5rem"}}>
                                <Form.Label className="from_label">Email address</Form.Label>
                                <Form.Control
                                  type="email"
                                  id="registeremail"
                                  placeholder=""
                                  name="email"
                                  required
                                  value={rstate.registeremail}
                                  onChange={inputRegisterChange}
                                 />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label className="from_label">Password</Form.Label>
                                <Form.Control
                                  type="password"
                                  id="registerpassword"
                                  placeholder=""
                                  name="password"
                                  value={rstate.registerpassword}
                                  onChange={inputRegisterChange}
                                  required
                                 />
                              </Form.Group>
                              <div style={{textAlign:"center"}}>
                                  <Button variant="primary" className="btn btn-lg btn-block submit_login_btn" type="submit">
                                    Create Account
                                  </Button>
                              </div>
                              <div className="row">
                                <div className="col-12 text-center m-0">
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                        <div className="row">
                         <div className="col-12 text-center m-0">
                           <p className="mb-0 login_register_title">Already have an account?
                             <a href="#" onClick={loginscreen} className="btn btn-md login_sub_title" style={{marginBottom:"4px",color:"#15B770"}} role="button">
                                Sign in
                              </a>
                          </p>
                         </div>
                        </div>
                    </div>
                    }
                    {showlogin == "loginpage" &&
                    <div>
                      <div className="row">
                      <div className="col-12">
                        <Form onSubmit={handleSubmit} style={{marginBottom:"0.5rem"}}>
                          <Form.Group>
                            <Form.Label className="from_label">Email address</Form.Label>
                            <Form.Control
                              type="email"
                              id="email"
                              placeholder=""
                              name="email"
                              required
                              value={state.email}
                              onChange={inputChange}
                             />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label className="from_label">Password</Form.Label>
                            <Form.Control
                              type="password"
                              id="password"
                              placeholder=""
                              name="password"
                              value={state.password}
                              onChange={inputChange}
                              required
                             />
                          </Form.Group>
                          <div style={{textAlign:"center"}}>
                              <Button variant="primary" className="btn btn-lg btn-block submit_login_btn" type="submit">
                                Submit
                              </Button>
                          </div>
                        </Form>
                      </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center">
                            <p className="login_sub_title">Not A Member
                               <a href="#" onClick={register} className="btn btn-md login_sub_title" style={{marginBottom:"4px",color:"#15B770"}} role="button">Join Earthly</a>
                            </p>
                        </div>
                      </div>
                    </div>
                  }

                  </div>
                 </div>
                </div>
                <div className="row login_img_section">
                   <img src={earthly_bottom_signup} alt="bottom_signup" className="bottom_signup_img"/>
                </div>
             </div>
             <div className="errorblock">
               <Row style={{marginRight:"140px"}}>
               <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                 <Toast.Header>
                 </Toast.Header>
                 <Toast.Body>{emessage}</Toast.Body>
               </Toast>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      </Container>

    </div>



  )



}

export default Login;
